/* ExplorePage.css */

.explore-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 80px 20px 20px;
    padding: 40px 20px 20px;
}

.explore-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    width: 100%;
    max-width: 1400px;
    margin-left: auto;
}

.explore-item {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 110%;
    background-color: #f5f5f5;
}

.explore-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Media queries for specific devices */

/* iPhone SE */
@media only screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
    .explore-container {
        margin-top: 60px;
        padding-top: 20px;
    }
}

/* iPhone XR */
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    .explore-container {
        margin-top: 80px;
        padding-top: 30px;
    }
}

/* iPhone 12 Pro */
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
    .explore-container {
        margin-top: 90px;
        padding-top: 35px;
    }
}

/* iPhone 14 Pro Max */
@media only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) {
    .explore-container {
        margin-top: 90px;
        padding-top: 35px;
    }
}

/* Pixel 7 */
@media only screen and (device-width: 393px) and (device-height: 851px) and (-webkit-device-pixel-ratio: 3) {
    .explore-container {
        margin-top: 75px;
        padding-top: 30px;
    }
}

/* Samsung Galaxy S8+ */
@media only screen and (device-width: 360px) and (device-height: 740px) and (-webkit-device-pixel-ratio: 4) {
    .explore-container {
        margin-top: 85px;
        padding-top: 35px;
    }
}

/* Samsung Galaxy S20 Ultra */
@media only screen and (device-width: 412px) and (device-height: 915px) and (-webkit-device-pixel-ratio: 3.5) {
    .explore-container {
        margin-top: 85px;
        padding-top: 35px;
    }
}

/* iPad Mini */
@media only screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) {
    .explore-container {
        margin-top: 100px;
        padding-top: 40px;
    }
}

/* Surface Duo */
@media only screen and (device-width: 540px) and (device-height: 720px) and (-webkit-device-pixel-ratio: 2.5) {
    .explore-container {
        margin-top: 70px;
        padding-top: 30px;
    }
}

/* Galaxy Z Fold 5 */
@media only screen and (device-width: 280px) and (device-height: 653px) and (-webkit-device-pixel-ratio: 3) {
    .explore-container {
        margin-top: 60px;
        padding-top: 20px;
    }
}

/* Samsung Galaxy A51/71 */
@media only screen and (device-width: 412px) and (device-height: 914px) and (-webkit-device-pixel-ratio: 2.625) {
    .explore-container {
        margin-top: 85px;
        padding-top: 35px;
    }
}

/* iPad Pro */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .explore-container {
        padding-left: 200px;
    }
}

/* Surface Pro 7 */
@media only screen and (min-width: 1368px) and (max-height: 912px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .explore-container {
        padding-left: 200px;
    }
}

/* Nest Hub Max */
@media only screen
    and (min-width: 1280px)
    and (max-width: 1280px)
    and (min-height: 800px)
    and (max-height: 800px) {
    .explore-container {
        padding-left: 200px;
        margin-right: 20px;
    }
}
