/* Navbar.css */

#navbar {
  display: flex;
  background-color: #f4f7f9;
  font-family: 'Arial', sans-serif;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 10px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  min-width: 320px;
}

/* Mobile-first styles */
#navbar .navbar-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

#navbar .navbar-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#navbar .hamburger-menu {
  display: block;
  cursor: pointer;
  margin-right: 10px;
  padding: 5px;
}

#navbar .hyroclipse-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#navbar .navbar-hyroclipse-logo {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e6e6e6;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

#navbar .hyrofits-logo {
  display: flex;
  align-items: center;
}

#navbar .navbar__search {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  margin-left: 10px;
}

#navbar .search-input {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 16px;
  flex-grow: 1;
  width: 100%;
}

#navbar .search-icons {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

#navbar .search-icon {
  color: #aaa;
  cursor: pointer;
}

#navbar .clear-search-icon {
  margin-right: 10px;
  color: #aaa;
  cursor: pointer;
}

/* Navigation icons */
#navbar .nav-icons {
  display: none;
}

#navbar .nav-icons-large {
  display: none;
}

#navbar .navbar-item {
  font-size: 1.5rem;
  margin: 0 5px;
  color: #333;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 20px;
  background: none;
  border: none;
}

#navbar .navbar-item:hover {
  color: #007bff;
}

#navbar .navbar-icon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#navbar .navbar-logo {
  width: 30px;
  height: 30px;
  margin: 0;
}

#navbar .navbar-item .unread-messages-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  font-size: 0.75rem;
  padding: 0.25em 0.4em;
  border-radius: 50%;
  font-weight: bold;
  min-width: 1.5em;
  text-align: center;
}

/* Desktop-only icons */
.desktop-only-icons {
  display: none;
}

/* Updated Mobile/Tablet Styles */
@media (max-width: 1180px) {
  #navbar {
    flex-direction: row;
    padding: 10px;
    height: auto;
  }

  #navbar .navbar-top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    margin-bottom: 0;
    margin-right: 10px;
  }

  #navbar .navbar-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    flex-grow: 1;
  }

  #navbar .hamburger-menu {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    margin-right: 10px;
    padding: 5px;
  }

  #navbar .hyroclipse-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    margin-right: 10px;
  }

  #navbar .navbar-hyroclipse-logo {
    width: 56px;
    height: 56px;
  }

  #navbar .navbar__search {
    flex-grow: 1;
    margin: 0 10px;
    max-width: none;
  }

  #navbar .search-input {
    height: 35px;
    font-size: 16px;
    padding: 5px 10px;
  }

  #navbar .hyrofits-logo {
    display: none;
  }

  #navbar .nav-icons-large,
  #navbar .nav-icons {
    display: none;
  }

  #navbar .navbar-item {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
  }
}

/* Desktop Styles */
@media (min-width: 1181px) {
  #navbar {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
  }

  #navbar .navbar-left {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  #navbar .navbar-top,
  #navbar .navbar-bottom {
    display: none;
  }

  #navbar .hamburger-menu {
    display: none;
  }

  #navbar .navbar__search {
    margin-left: 30px;
    max-width: 500px;
  }

  #navbar .nav-icons-large {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }

  #navbar .nav-icons {
    display: flex;
    align-items: center;
  }

  #navbar .nav-icons-large .navbar-icon {
    width: 32px;
    height: 32px;
    margin: 0 10px;
    font-size: 22px;
    cursor: pointer;
  }

  #navbar .nav-icons-large .navbar-icon:hover {
    color: #007bff;
  }

  #navbar .nav-icons .navbar-item {
    font-size: 1.1rem;
    margin: 0 5px;
    padding: 5px 15px;
    border-radius: 20px;
    background-color: #fff;
    border: 2px solid #333;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
  }

  #navbar .nav-icons .navbar-item:hover {
    background-color: #f8f9fa;
    color: #007bff;
  }

  #navbar .nav-icons .navbar-item .navbar-icon {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }

  #navbar .nav-icons .navbar-item .text {
    font-size: 0.9rem;
    font-weight: 500;
  }

  #navbar .nav-icons .logo-link {
    background: none;
    border: none;
    padding: 0;
    margin-right: 15px;
  }
}

@media (min-width: 1264px) {
  #navbar .navbar-item {
    padding: 5px 10px;
    font-size: 1.1rem;
    border-radius: 12px;
  }

  #navbar .navbar__search {
    margin-left: 250px;
    margin-right: auto;
    max-width: 900px;
  }

  #navbar .navbar-icon {
    width: 12px;
    height: 12px;
  }

  .navbar-icon-bio,
  .navbar-icon-newposts,
  .navbar-icon-explore {
    width: 32px !important;
    height: 32px !important;
    font-size: 22px !important;
  }
}

@media (min-width: 1300px) {
  #navbar .navbar-item {
    padding: 5px 10px;
    font-size: 1.1rem;
    border-radius: 12px;
  }

  #navbar .navbar__search {
    margin-left: 250px;
    margin-right: auto;
    max-width: 900px;
  }

  #navbar .nav-icons-large .navbar-icon {
    width: 32px;
    height: 32px;
    margin: 0 20px;
  }

  #navbar .navbar-icon {
    width: 12px;
    height: 12px;
  }
}

/* Device-specific media queries */
/* iPad Air, iPad Pro, Surface Pro 7, Nest Hub, and similar devices */
@media only screen 
  and (min-width: 768px)
  and (max-width: 1180px) {
  #navbar {
    flex-direction: row;
    padding: 10px;
    height: auto;
  }

  #navbar .navbar-top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    margin-bottom: 0;
    margin-right: 10px;
  }

  #navbar .navbar-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    flex-grow: 1;
  }

  #navbar .hamburger-menu {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    margin-right: 10px;
    padding: 5px;
  }

  #navbar .hyroclipse-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    margin-right: 10px;
  }

  #navbar .navbar__search {
    flex-grow: 1;
    margin: 0 10px;
    max-width: none;
  }

  #navbar .nav-icons-large,
  #navbar .nav-icons {
    display: none;
  }

  #navbar .navbar-item {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
  }

  #navbar .hyrofits-logo {
    display: none;
  }
}

/* iPad Air */
@media only screen 
  and (min-device-width: 820px) 
  and (max-device-width: 1180px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  #navbar .navbar__search {
    margin-left: 200px;
    max-width: 40%;
  }

  #navbar .navbar-item {
    font-size: 1rem;
    padding: 5px 10px;
  }

  #navbar .navbar-icon {
    width: 18px;
    height: 18px;
  }

  #navbar .navbar-logo {
    width: 30px;
    height: 30px;
  }
}

/* iPad Pro */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1180px)
  and (-webkit-min-device-pixel-ratio: 1.5) {
  #navbar .navbar__search {
    margin-left: 200px;
  }

  #navbar .navbar-item {
    font-size: 1.2rem;
    padding: 5px 10px;
  }

  #navbar .navbar-icon {
    width: 20px;
    height: 20px;
  }
}

/* Surface Pro 7 */
@media only screen 
  and (min-device-width: 912px)
  and (max-device-width: 1180px)
  and (-webkit-min-device-pixel-ratio: 1.5) {
  #navbar .navbar__search {
    margin-left: 250px;
  }

  #navbar .navbar-item {
    font-size: 1.2rem;
    padding: 5px 10px;
  }

  #navbar .navbar-icon {
    width: 20px;
    height: 20px;
  }
}

/* Nest Hub */
@media only screen 
  and (min-device-width: 1024px)
  and (max-device-width: 1180px) {
  #navbar .navbar__search {
    margin-left: 200px;
    max-width: 40%;
  }

  #navbar .navbar-item {
    font-size: 1.2rem;
    padding: 5px 10px;
  }

  #navbar .navbar-icon {
    width: 20px;
    height: 20px;
  }
}

/* Galaxy Z Fold 5 */
@media (min-width: 816px) and (max-width: 2208px) {
  #navbar .navbar-item {
    font-size: 0.9rem;
    padding: 2px 4px;
  }

  #navbar .navbar-icon {
    width: 14px;
    height: 14px;
  }
}

/* Small screens */
@media (max-width: 480px) {
  #navbar .navbar__search {
    max-width: none;
  }

  #navbar .search-input {
    font-size: 16px;
  }

  #navbar .navbar__search .search-input::placeholder {
    font-size: 16px;
  }

  #navbar .navbar__search .search-input::-webkit-input-placeholder {
    font-size: 16px;
  }

  #navbar .navbar__search .search-input::-moz-placeholder {
    font-size: 16px;
  }

  #navbar .navbar__search .search-input:-ms-input-placeholder {
    font-size: 16px;
  }
}

@media (max-width: 375px) {
  #navbar .navbar-item {
    padding: 5px 10px;
    font-size: 1.2rem;
  }

  #navbar .navbar-icon {
    width: 20px;
    height: 20px;
  }
}

/* Adjust the search input size for better visibility on small screens */
@media (max-width: 480px) {
  #navbar.search-input {
    height: 35px;
    font-size: 16px;
    padding: 5px 10px;
  }

  #navbar.search-input::placeholder {
    font-size: 16px;
  }

  #navbar.search-input::-webkit-input-placeholder {
    font-size: 16px;
  }

  #navbar.search-input::-moz-placeholder {
    font-size: 16px;
  }

  #navbar.search-input:-ms-input-placeholder {
    font-size: 16px;
  }
}

/* Prevent zooming on mobile and tablet screens */
@media (max-width: 1180px) {
  #navbar.search-input {
    touch-action: manipulation;
  }
}

/* New Media Query for Viewport Width Between 1181px and 1263px */
@media (min-width: 1181px) and (max-width: 1263px) {
  #navbar .navbar__search {
    margin-left: 220px !important; /* Slightly shift further to the right */
    max-width: 400px !important;  /* Keep the reduced width */
  }
}

@media only screen and (min-width: 768px) and (max-width: 1180px) {
  #navbar .navbar__search {
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: none !important;
    width: 80% !important; /* Slightly less than full width */
    margin: 0 auto; /* Center the search bar horizontally if desired */
  }

  #navbar .search-input {
    width: 100% !important;
  }
}