/* PostForm.css */

/* Basic Reset for Form Elements */
* {
    box-sizing: border-box;
    outline: none;
}

/* Smooth transition for any hover or focus effects */
*,
*:hover,
*:focus {
    transition: all 0.3s ease;
}

/* Main Form Container Styling */
.form-container {
    position: relative;
    max-width: calc(100% - 180px);
    margin-left: 180px;
    margin-top: 200px; /* Increased margin-top to move the form down */
    margin-bottom: 70px;
    padding: 40px;
    background-color: #1f1f1f;
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    animation: fadeIn 0.7s forwards;
}

/* PostVerification specific styling for 1264px width */
@media (min-width: 1264px) and (max-width: 1264px) {
    .form-container {
        margin-top: 200px; /* Increased margin-top to move the form down */
    }
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    z-index: 10;
}

/* Form Title Styling */
.form-title {
    font-size: 24px;
    margin-bottom: 25px;
    color: #ffffff;
    letter-spacing: -1px;
    border-bottom: 2px solid #333;
    padding-bottom: 15px;
    text-align: left;
    font-weight: bold; /* Ensure the main heading is bold */
}

/* General Group Styling */
.form-group {
    margin: 40px 0;
}

/* Labels inside the Form Group */
.form-group label {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    color: black !important; /* Ensure all labels are black */
    letter-spacing: 0.5px;
    text-align: left;
    font-weight: bold; /* Make sub-headings bold */
}

/* Input and Textarea Styling */
.form-input,
.form-textarea,
.form-options input[type="checkbox"] {
    width: 100%;
    padding: 15px 20px;
    background-color: white !important; /* Changed background to white */
    border-radius: 10px;
    border: 1px solid black !important; /* Added black border */
    font-size: 16px;
    color: black !important; /* Changed font color to black */
    font-family: inherit; /* Ensure font family is inherited */
}

/* Placeholder Color */
.form-input::placeholder,
.form-textarea::placeholder,
.drag-drop-zone p {
    color: #777777; /* Reverted placeholder color */
    font-size: 16px;
    font-family: inherit; /* Ensure font family is inherited */
}

/* Focus Effects */
.form-input:focus,
.form-textarea:focus,
.form-options input[type="checkbox"]:focus {
    background-color: #f3f3f3; /* Lighter background on focus */
    box-shadow: 0 0 5px rgba(28,169,255,0.5);
}

/* Styling the options for checkboxes */
.form-options {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjusted gap for better spacing */
    align-items: center;
    justify-content: flex-start;
}

.form-options div {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 180px; /* Ensures consistent width for alignment */
}

.form-options input[type="checkbox"] {
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin: 0;
    margin-right: 8px; /* Space between checkbox and label */
}

.form-options label {
    color: black !important; /* Ensure all labels are black */
    margin: 0;
    padding: 0;
    font-size: 14px; /* Adjusted for smaller text size */
    flex: 1; /* Ensures label takes the remaining space */
}

/* Radio Button Text Color */
.form-options input[type="radio"] + label {
    color: black !important; /* Ensure radio button labels are black */
}

/* Button Styling */
.form-button {
    padding: 15px 30px;
    background-color: #1ca9ff;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    display: block;
    margin: 20px auto;
}

/* Button Hover Effect */
.form-button:hover {
    background-color: #1691cc;
}

/* Button Active Effect */
.form-button:active {
    transform: scale(0.98);
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Applying fade-in animation */
.form-container {
    animation: fadeIn 0.7s forwards;
}

.upload__image-wrapper {
    margin-bottom: 20px;
}

.image-item {
    display: inline-block;
    position: relative;
    margin-right: 10px;
}

.image-item__btn-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
}

.image-item:hover .image-item__btn-wrapper {
    display: block;
}

/* Drag & Drop Zone Styling */
.drag-drop-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #4CAF50;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: white !important; /* Changed background to white */
    color: #292929; /* Changed text color to a dark shade for contrast */
}

/* Upload Icon Styling */
.upload-icon,
.images-icon {
    color: #4CAF50;
    margin-bottom: 10px;
    margin-top: 10px;
}

.uploaded-image-preview {
    max-width: 100%;
    max-height: 150px;
    height: auto;
    display: block;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    object-fit: contain;
}

/* Resetting any browser default styles that might affect alignment */
input[type='checkbox'] {
    -webkit-appearance: checkbox;
    appearance: checkbox;
}

/* Horizontal alignment of checkboxes and labels */
.form-options {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
}

.form-options div {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 180px; /* Ensures consistent width for alignment */
}

.form-options input[type="checkbox"] {
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin: 0;
    margin-right: 8px; /* Space between checkbox and label */
}

.form-options label {
    color: black !important; /* Ensure all labels are black */
    margin: 0;
    padding: 0;
    font-size: 14px; /* Adjusted for smaller text size */
    flex: 1; /* Ensures label takes the remaining space */
}

.form-container .form-options {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
}

.form-container .form-options div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.form-container .form-options input[type="checkbox"] {
    margin-right: 5px;
}

.form-options > div {
    display: inline-flex;
    margin-right: 10px;
    align-items: center;
    width: 180px; /* Consistent width */
}

@media (max-width: 768px) {
    .form-container {
        max-width: 100%;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 200px; /* Increased margin-top to move the form down */
        padding: 20px;
    }
}

@media (min-width: 768px) {
    .form-container {
        max-width: 600px;
        margin-left: 180px;
    }
}

.password-verification {
    max-width: calc(100% - 180px);
    margin-left: 250px;
    margin-top: 100px;
    margin-bottom: 70px;
    padding: 40px;
    background-color: #1f1f1f;
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.7s forwards;
}

.password-verification-title {
    font-size: 34px;
    color: #ffffff;
    margin-bottom: 25px;
    border-bottom: 2px solid #333;
    padding-bottom: 15px;
    text-align: center;
}

.password-verification-form-group {
    margin: 40px 0;
    text-align: center;
}

.password-verification-form-group label {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    color: #dddddd;
}

.password-verification-input {
    width: 100%;
    padding: 15px 20px;
    background-color: #292929;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
}

.password-verification-input:focus {
    background-color: #3a3a3a;
    box-shadow: 0 0 5px rgba(28,169,255,0.5);
}

.password-verification-button {
    padding: 15px 30px;
    background-color: #1ca9ff;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    display: block;
    margin: auto;
}

.password-verification-button:hover {
    background-color: #1691cc;
}

.password-verification-button:active {
    transform: scale(0.98);
}

.password-error {
    color: #ff6b6b;
    text-align: center;
    margin-top: 10px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.form-input[type='number']::placeholder {
    color: #777777;
}

.image-preview-container {
    position: relative;
    display: inline-block;
}

.uploaded-image-preview {
    max-width: 100%;
    max-height: 150px;
    height: auto;
    display: block;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    object-fit: contain;
}

.remove-image-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background-color: #ffffff;
    color: #ff0000;
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    z-index: 10;
}

.suggestion-link {
    display: flex;
    align-items: center;
    color: #007bff;
    font-size: 16px;
    text-decoration: none;
}

.suggestion-link svg {
    margin-left: 5px;
    font-size: 20px;
}

@media (min-width: 1246px) {
    .form-container {
        margin-top: 200px; /* Adjusted margin-top to move the form down */
    }
}

/* Word Count Styling */
.word-count {
  font-size: 14px;
  color: black;
  text-align: right;
  margin-top: 5px;
}

.word-count-exceeded {
  color: #ff6b6b; /* Red color for exceeded word count */
}

.error-message {
  color: #ff6b6b;
  font-size: 14px;
  margin-top: 5px;
}

.error-message,
.centered-error {
    text-align: center;
    font-size: 1.2em;
    color: red;
    margin-bottom: 15px;
}

/* Styling for Social Media Inputs */
.social-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.social-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.social-input input {
  flex-grow: 1;
}

.social-icon.instagram {
  color: #E4405F; /* Instagram color */
}

.social-icon.twitter {
  color: #1DA1F2; /* Twitter color */
}

.social-icon.tiktok {
  color: #69C9D0; /* TikTok color */
}

.social-icon.linkedin {
  color: #0077B5; /* LinkedIn color */
}

.social-icon.youtube {
  color: #FF0000; /* YouTube color */
}

/* Styling for the generated summary text */
.generated-summary {
  font-size: 16px;
  color: #ffffff; /* Light color for contrast against dark background */
  margin-top: 10px;
  line-height: 1.5;
  text-align: left;
  overflow-wrap: break-word;
}

/* Styling for the generated summary header */
.summary-title {
  font-size: 18px;
  color: #ffffff; /* Light color for contrast against dark background */
  margin-top: 20px;
  text-align: left;
}

/* Error state for input and textarea fields */
.form-input.error, .form-textarea.error {
  border: 2px solid red !important;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.error-message {
  font-size: 1.2em;
  color: red;
  margin-bottom: 5px;
  text-align: center;
}

.generate-bio-link {
  text-decoration: underline;
  color: blue;
  margin-left: 5px;
}

/* Custom Cropper Styles */
.cropper-container {
    position: relative;
    width: 100%;
    max-width: 500px; /* Adjust the max-width as needed */
    margin: 0 auto;
    background-color: #000;
    border-radius: 10px;
    overflow: hidden;
}

.cropper-image {
    display: block;
    width: 100%; /* Ensure the image takes up the full width */
    height: auto; /* Maintain the aspect ratio */
    border-radius: 10px;
}

.cropper-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
    border-radius: 10px;
}

.cropper-toolbar {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ccc;
}

.cropper-toolbar button {
    background: none;
    border: none;
    color: #007bff;
    font-size: 16px;
    cursor: pointer;
}

.cropper-toolbar button:hover {
    text-decoration: underline;
}

.cropper-wrapper {
    position: relative;
}

.remove-image-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: #ffffff;
    color: #ff0000;
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    z-index: 10;
}

.cropper-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.cropper-toolbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    background-color: #FFF; 
}

.save-button, .cancel-button {
    background-color: #4CAF50; 
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.save-button:hover, .cancel-button:hover {
    opacity: 0.8;
}

/* Circular Profile Image Dropzone */
.image-upload-section.profile-image .drag-drop-zone.circular {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #4CAF50;
    margin: 0 auto;
}

.image-upload-section.profile-image .image-preview-container.circular {
    position: relative;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
}

.image-upload-section.profile-image .uploaded-image-preview {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}

.image-upload-section.profile-image .remove-image-icon,
.image-upload-section.profile-image .edit-button {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    color: #ff0000;
    border-radius: 50%;
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.image-upload-section.profile-image .remove-image-icon {
    top: -15px;
    right: -15px;
}

.image-upload-section.profile-image .edit-button {
    bottom: -15px;
    right: -15px;
}

/* Circular Cropper */
.cropper-wrapper.circular {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.cropper-container.circular {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}

.cropper-container.circular .reactEasyCrop_Container {
    border-radius: 50%;
}

/* General Image Preview */
.image-preview-container {
    position: relative;
    display: inline-block;
    margin: 0 auto;
}

.uploaded-image-preview {
    max-width: 100%;
    max-height: 200px;
    height: auto;
    display: block;
    margin: 10px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    object-fit: contain;
}

/* Cropper Toolbar */
.cropper-toolbar {
    background-color: transparent;
    padding: 10px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

.cropper-toolbar button {
    padding: 8px 16px;
    margin: 0 5px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.cropper-toolbar button:hover {
    background-color: #45a049;
}