/* MessagesPage.css */

.messages-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 250px;
  font-family: 'Arial', sans-serif;
  margin-top: 0;
  position: relative;
  z-index: 1;
}

.main-content {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: row;
}

.main-content .chat-window-section {
  flex-grow: 3;
  margin-left: 2%;
  width: auto;
}

.spacer {
  flex-grow: 2;
}

.users-list {
  width: 30%;
  height: 100vh;
  border-right: 1px solid #dbdbdb;
  overflow-y: auto;
  padding: 20px;
  margin-right: 2%;
}

.main-content .chat-window-section {
  flex-grow: 3;
  margin-left: 2%;
  width: auto;
}

.user {
  display: flex;
  align-items: center;
  padding: 20px 0;
  cursor: pointer;
  border-bottom: 1px solid #dbdbdb;
}

.user:hover {
  background-color: #f5f5f5;
}

.user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.text-content {
  margin-left: 20px;
}

.username {
  font-weight: 600;
  margin-bottom: 5px;
}

.last-message {
  color: #8e8e8e;
}

.chat-section {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fafafa;
}

.message-bubble {
  max-width: 60%;
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  align-self: flex-start;
  background-color: #efefef;
  color: #262626;
}

.message-bubble.sender {
  align-self: flex-end;
  background-color: #0f93f4;
  color: white;
}

.input-area {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #dbdbdb;
  background-color: #fff;
}

.input-area input {
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #dbdbdb;
  margin: 0 10px;
}

.icon {
  font-size: 24px;
  color: #262626;
  opacity: 0.6;
  margin-right: 20px;
}

.icon:last-child {
  margin-right: 0;
}

.icon:hover {
  opacity: 1;
}

.time-stamp {
  margin-left: 10px;
  color: #8e8e8e;
  font-size: 0.8em;
}

/* Input Area Styles */
.input-area {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid #dbdbdb;
  background-color: #fff;
}

.icon {
  font-size: 24px;
  color: #262626;
  opacity: 0.6;
  margin-right: 20px;
  transition: opacity 0.3s;
}

.icon:last-child {
  margin-right: 0;
}

.icon:hover {
  opacity: 1;
}

.input-area input {
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #dbdbdb;
  margin: 0 10px;
  transition: border-color 0.3s;
}

.input-area input:focus {
  outline: none;
  border-color: #0f93f4;
}

/* Message Bubble Styles */
.message-bubble {
  max-width: 60%;
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  background-color: #efefef;
  color: #262626;
}

.sender {
  align-self: flex-end;
  background-color: #0f93f4;
  color: white;
}

.receiver {
  background-color: #efefef;
  color: #262626;
}

.chat-profile-pic {
  border: none;
}

.user-name, .last-message {
  margin-left: 10px;
}

.user-search {
  padding: 10px;
  border-bottom: 1px solid #dbdbdb;
}

.user-search input {
  width: 100%;
  padding: 7px 10px;
  border-radius: 15px;
  border: 1px solid #dbdbdb;
  box-shadow: none;
}

.notification-badge {
  background-color: #0f93f4;
  color: white;
  border-radius: 12px;
  padding: 2px 7px;
  font-size: 12px;
  font-weight: bold;
  margin-left: auto;
}

.new-chat-button-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

/* Screens 1180px or smaller */
@media screen and (max-width: 1180px) {
  .messages-container {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .messages-container {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .messages-container {
    margin-top: 40px;
    margin-left: 0;
  }

  .main-content {
    flex-direction: column;
  }

  .users-list {
    width: 100%;
    display: block;
    height: auto;
    border-right: none;
  }

  .main-content .chat-window-section,
  .main-content .users-list {
    margin: 0;
  }

  .new-chat-button-section {
    order: -1;
  }
}

@media (max-width: 480px) {
  .messages-container {
    margin-top: -30px;
  }

  .header,
  .new-chat-button-section {
    padding: 10px;
  }
}

/* Device-specific media queries */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .messages-container {
    margin-top: -30px;
  }
}

@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
  .messages-container {
    margin-top: -20px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .messages-container {
    margin-top: -10px;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .messages-container {
    margin-top: -10px;
  }
}

@media only screen and (min-device-width: 912px) and (max-device-width: 1368px) {
  .messages-container {
    margin-top: -15px;
  }
}

@media (min-width: 540px) and (min-height: 720px) and (max-width: 720px) {
  .messages-container {
    margin-top: -65px;
  }
}

@media (min-width: 1920px) and (max-width: 2560px) {
  .messages-container {
    margin-top: 0;
  }
}

@media (min-width: 1024px) and (max-width: 600px) {
  .messages-container {
    margin-top: -70px;
  }
}

@media (min-width: 1280px) and (max-width: 800px) {
  .messages-container {
    margin-top: -60px;
  }
}

@media (min-width: 1264px) {
  .messages-container {
    margin-top: 0;
  }
}

/* New overrides for larger screens */
@media (min-width: 1180px) {
  .message-bubble {
    max-width: 80%;
  }

  .main-content {
    width: 100%;
  }

  .messages-container {
    width: calc(100% - 250px);
    overflow: visible;
    margin-top: 20px; /* Added this line to move content slightly down */
  }
}
