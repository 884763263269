/* InfoPage.css */

.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    margin: 80px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-left: 180px;
    border: 2px solid #000;
}

.info-container section {
    margin-bottom: 20px;
}

.info-container h1, 
.info-container h2 {
    color: #333;
    margin-bottom: 10px;
}

.info-container p,
.info-container li {
    color: #666;
    line-height: 1.6;
    margin-bottom: 10px;
}

.welcome-section h1 {
    color: #0275d8;
}

.terms-of-use, 
.disclaimer-section, 
.claims-section, 
.miscellaneous-section {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Styles for Contact Section */
.contact-section {
    background: none;
    margin-bottom: 20px;
}

.contact-section .sidebar-menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.contact-section .sidebar-item {
    display: inline-flex;
    align-items: center;
    padding: 4px 6px; /* Compact padding for box */
    margin: 0;
    border: 1px solid #ddd; /* Adds a visible border */
    border-radius: 6px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    width: fit-content; /* Adjust box size to content */
    background-color: #fff; /* Default box background */
}

.contact-section .sidebar-link {
    display: flex;
    align-items: center;
    color: #262626;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 8px; /* Ensures hover area matches box */
    border-radius: 6px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.contact-section .sidebar-link:hover {
    background-color: #0095f6; /* Highlight on hover */
    border-color: #0095f6; /* Border matches hover color */
}

.contact-section .sidebar-link-content {
    display: flex;
    align-items: center;
    gap: 6px; /* Controls spacing between icon and text */
}

.contact-section .sidebar-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-section .sidebar-icon {
    font-size: 18px; /* Consistent icon size */
}

.contact-section .sidebar-text {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

/* Responsive Design Adjustments */
.info-container, 
.info-container h1, 
.info-container h2, 
.info-container h3, 
.info-container p, 
.info-container ul, 
.info-container li {
    text-align: left;
}

.info-container h1 {
    font-size: 2rem;
}

.info-container h2 {
    font-size: 1.75rem;
}

.info-container p,
.info-container li {
    font-size: 1rem;
}

/* Adjustments for medium screens (less than 992px) */
@media screen and (max-width: 992px) {
    .info-container {
        margin: 180px 20px 20px;
    }
}

/* Adjustments for small screens (less than 768px) */
@media screen and (max-width: 768px) {
    .info-container {
        margin: 180px 20px 20px;
        max-width: calc(100% - 40px);
    }
}

/* Further adjustments for extra-small screens (less than 576px) */
@media screen and (max-width: 576px) {
    .info-container {
        margin: 180px 20px 20px;
    }

    .info-container h1 {
        font-size: 1.5rem;
    }

    .info-container h2 {
        font-size: 1.3rem;
    }

    .info-container p,
    .info-container li {
        font-size: 0.9rem;
    }
}

/* Adjustments for large screens (greater than 1300px) */
@media screen and (min-width: 1300px) {
    .info-container {
        margin-top: 120px;
    }
}

/* Adjustments for 1264px width screens */
@media screen and (max-width: 1264px) and (min-width: 1264px) {
    .info-container {
        margin-top: 150px;
    }
}
