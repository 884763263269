/* ManagePosts.css */

.post-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.post-item {
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  align-items: center; /* Center-align items */
  margin-bottom: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e7e7e7;
}

.post-thumbnail {
  width: 60px; /* Thumbnail size */
  height: 60px;
  object-fit: cover;
  margin-bottom: 10px; /* Space below thumbnail */
  border-radius: 4px;
}

.post-title {
  color: #4267B2;
  text-decoration: none;
  margin-bottom: 10px;
  text-align: center; /* Center-align text */
}

.post-title:hover {
  text-decoration: underline;
}

.info-row {
  margin-top: 10px; /* Space between delete button and info rows */
  padding-top: 10px;
  border-top: 1px solid #e7e7e7;
  width: 100%; /* Extend to full width for consistent alignment */
}

.info-row div {
  margin-bottom: 5px; /* Spacing between each info item */
}

.info-label {
  font-weight: bold;
  margin-right: 8px;
}

.info-content {
  color: #333;
}

.button-delete {
  margin-top: 10px; /* Space above delete button */
  align-self: center; /* Center-align delete button */
}
