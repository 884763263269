/* MyPostsPage.css */

/* Container for the My Posts page */
.my-posts-container {
  max-width: calc(100% - 350px); /* Accommodate the Sidebar width */
  margin: 120px auto 20px;
  padding: 20px;
  /* Keep padding-left as 0 to maintain original card widths */
  padding-left: 0;
  
  /* Shift the entire container 80px to the right without affecting widths */
  position: relative;
  left: 80px;
}

/* Heading styles */
.my-posts-container h1 {
  margin-bottom: 20px;
  text-align: center; /* Ensure heading is centered */
}

/* Specific styles for post-container within my-posts-container */
.my-posts-container .post-container {
  width: 100%;
  max-width: 700px; /* Adjust the max-width as needed */
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

/* Styled Button */
.styled-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
  background-color: #0095f6; /* Matches the Sidebar hover color */
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin: 20px auto; /* Centers the button horizontally and adds spacing */
}

.styled-button:hover {
  background-color: #007bb5; /* Darker blue for hover effect */
  transform: translateY(-2px); /* Subtle lift on hover */
}

.styled-button:active {
  transform: translateY(0); /* Return to original position on click */
}

.button-icon {
  font-size: 1.5rem; /* Matches Sidebar icon size */
  margin-right: 8px;
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 767px) {
  .my-posts-container {
    max-width: 100%;
    margin: 200px auto 10px;
    padding: 20px 10px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .my-posts-container {
    max-width: calc(100% - 350px); /* Adjust for Sidebar */
    margin-top: 200px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    max-width: 90%;
  }
}

@media screen and (min-width: 768px) and (max-width: 820px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    max-width: 500px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    max-width: 700px;
  }
}

@media screen and (min-width: 1368px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    max-width: 700px;
  }
}

@media screen and (min-width: 1919px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    padding-left: 0;
    padding-right: 20px;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    max-width: 400px;
    padding: 10px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin: 20px auto;
    padding: 20px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    max-width: 300px;
    padding: 10px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 800px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-top: 180px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    max-width: 550px;
  }
}

@media screen and (min-width: 768px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    max-width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }
}

@media screen and (min-width: 1300px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-top: 140px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }
}

/* iPad Mini */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-top: 240px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }
}

/* iPad Air */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-top: 260px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    max-width: 600px;
  }
}

/* iPad Pro */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-top: 280px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    max-width: 700px;
  }
}

/* Surface Pro 7 */
@media only screen and (min-device-width: 912px) and (max-device-width: 1368px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-top: 260px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }

  .my-posts-container .post-container {
    width: 80%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Asus Zenbook Fold */
@media only screen and (min-device-width: 1920px) and (max-device-width: 2560px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-top: 320px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }
}

/* Nest Hub */
@media only screen and (min-device-width: 1024px) and (max-device-width: 600px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-top: 240px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }
}

/* Nest Hub Max */
@media only screen and (min-device-width: 1280px) and (max-device-width: 800px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-top: 260px;
    padding-left: 0;
    left: 80px; /* Maintain right shift */
  }
}

/* Add media query for 1264px screen width */
@media (min-width: 1264px) and (max-width: 1264px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-left: auto;
    margin-right: auto;
    left: 80px; /* Maintain right shift */
  }
}

/* Add media query for screens with viewport width greater than 1180px */
@media screen and (min-width: 1181px) {
  .my-posts-container {
    max-width: calc(100% - 350px);
    margin-left: auto;
    margin-right: auto;
    left: 80px; /* Maintain right shift */
  }
}

/* Ensure the posts are centered on the page */
.my-posts-container .post-container {
  margin-left: auto;
  margin-right: auto;
}
