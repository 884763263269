/* Bio.css */

.posts {
  /* same styles from Trending.css: */
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .posts {
    width: calc(100% - 220px);
    margin-left: 200px;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .posts {
    padding-top: 40px;
    margin-left: 0;
    width: 100%;
  }
}

/* Adjust spacing between boxes */
@media (max-width: 1180px) {
  .posts {
    margin-top: 20px;
    padding-top: 80px;
  }
}
