/* Sidebar.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 220px;
  height: 100%;
  background: #ffffff;
  color: #262626;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

.mobile-header {
  display: none;
}

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  padding: 0 15px;
}

.profile-pic {
  width: 160px;
  height: 160px;
  margin-bottom: 15px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e6e6e6;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.profile-pic:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.profile-name-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile-name {
  font-size: 18px;
  font-weight: 700;
  color: #333;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  margin: 8px 0 0;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  margin-bottom: 8px;
  padding: 6px 8px;
  transition: background-color 0.3s ease;
  border-radius: 6px;
}

.sidebar-link {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  color: #262626;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
}

.sidebar-link:hover, .sidebar-item.active .sidebar-link {
  background-color: #0095f6;
}

.sidebar-link-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  margin-right: 15px;
}

.sidebar-icon {
  font-size: 24px;
}

.sidebar-text {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.sidebar-close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
}

@media (max-width: 1180px) {
  .sidebar {
    width: 100%;
    padding: 0;
  }

  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #ffffff;
  }

  .mobile-header .profile-name-container {
    margin: 0;
  }

  .mobile-header .profile-name {
    margin: 0;
    font-size: 20px;
  }

  .mobile-close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    padding: 5px;
  }

  .sidebar-close-button {
    display: none;
  }

  .profile-section {
    display: none;
  }

  .sidebar-menu {
    padding-top: 10px;
  }
}
