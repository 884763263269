/* MessagesList.css */

.messages-list-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.messages-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.no-messages {
  text-align: center;
  padding: 20px;
}

.List {
  padding: 0;
}

.chat-item {
  transition: background-color 0.2s ease-in-out;
}

.chat-item:hover {
  background-color: #f5f5f5;
}

.chat-user-img {
  width: 50px;
  height: 50px;
}

.unread-badge {
  background-color: #009688;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid white;
}

.chat-status {
  font-size: 0.875rem;
  color: #8e8e8e;
}

.ListItemText-primary {
  font-weight: bold;
  color: #262626;
}

.ListItemText-secondary {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ListItem-secondaryAction {
  right: 16px;
}

.MuiIconButton-root {
  padding: 8px;
}

.MuiTypography-caption {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.54);
}

@media (max-width: 768px) {
  .messages-list-container {
    max-width: 100%;
    box-shadow: none;
    border-radius: 0;
  }
}

@media (max-width: 600px) {
  .messages-list-container {
    border-radius: 0;
    box-shadow: none;
  }
}

.messages-list-container::-webkit-scrollbar {
  width: 8px;
}

.messages-list-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.messages-list-container::-webkit-scrollbar-thumb {
  background: #888;
}

.messages-list-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.MuiTooltip-tooltip {
  font-size: 0.75rem;
}

.MuiCard-root {
  transition: box-shadow 0.3s;
}

.MuiCard-root:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

@media (prefers-color-scheme: dark) {
  .messages-list-container,
  .MuiCard-root {
    background-color: #424242;
    color: #fff;
  }

  .messages-list-container::-webkit-scrollbar-track {
    background: #616161;
  }

  .messages-list-container::-webkit-scrollbar-thumb {
    background: #bdbdbd;
  }

  .unread-badge {
    background-color: #bbdefb;
  }
}

@media (max-width: 480px) {
  .messages-list-container::-webkit-scrollbar {
    display: none;
  }
}

/* New overrides for larger screens */
@media (min-width: 1180px) {
  .messages-list-container {
    max-width: 80%;
    margin: 0 auto;
  }
}
