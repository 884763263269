/* ContactList.css */

.small-contact-list-button {
    padding: 6px 12px; /* Smaller padding for a smaller button */
    background-color: #0095f6;
    color: #ffffff;
    border: none;
    border-radius: 10px; /* Slightly smaller border-radius */
    cursor: pointer;
    font-weight: 600;
    font-size: 0.85rem; /* Smaller font size */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); /* Smaller shadow */
    text-transform: none;
}

.small-contact-list-button:hover {
    background-color: #0077cc;
}
