/* Feedback.css */

.feedback-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;  /* ensures the feedback options are centered horizontally */
  gap: 10px;
}

.feedback-option {
  position: relative;
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smoother transition */
}

.feedback-option.selected {
  background-color: #d0e8ff; /* Using a soft highlight color when selected */
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.feedback-option:hover {
  background-color: #e0e0e0;
}

.feedback-option:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Slight shadow for focus */
}

.feedback-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #ff6b6b;
  color: white;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  transition: background-color 0.3s ease; /* Smoother count change transition */
}
