/* LoginPage.css */

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    margin: 120px auto; /* Increased top margin */
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    box-sizing: border-box;
}

.login-header {
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: bold;
}

.login-input {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    background-color: #f8f8f8;
    transition: background-color 0.2s;
}

.login-input:focus {
    background-color: #e8e8e8;
}

.login-button {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    background-color: #3897f0;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-button:hover {
    background-color: #307abf;
}

.login-links {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.forgot-link {
    margin-bottom: 10px;
    color: #3897f0;
    text-decoration: none;
}

.signup-text {
    font-size: 16px;
}

.signup-text a {
    color: #3897f0;
    text-decoration: none;
    margin-left: 5px;
}

.signup-text a:hover {
    text-decoration: underline;
}

.math-captcha {
    width: 100%;
    text-align: center; /* Ensure the MathCAPTCHA is centered */
    margin-bottom: 15px;
}

/* Add a media query for larger screens */
@media (min-width: 769px) {
    .login-container {
        margin-left: 250px; /* Adjust this value to shift the login box to the right */
    }
}

/* Adjustments for iPad Air */
@media (min-width: 820px) and (max-width: 1024px) {
    .login-container {
        margin-top: 150px; /* Further adjusted top margin for iPad Air */
    }
}

/* Adjustments for iPad Pro */
@media (min-width: 1024px) and (max-width: 1366px) {
    .login-container {
        margin-top: 150px; /* Further adjusted top margin for iPad Pro */
        margin-left: auto;
        margin-right: 60px; /* Adjusted margin to move right */
    }
}

/* Adjustments for Surface Pro 7 */
@media (min-width: 1368px) and (max-width: 1368px) {
    .login-container {
        margin-top: 150px;
        margin-left: 300px; /* Adjusted margin to move right and avoid the sidebar */
    }
}

/* Adjustments for Asus Zenbook Fold */
@media (min-width: 1920px) and (max-width: 1920px) {
    .login-container {
        margin-top: 150px; /* Further adjusted top margin for Asus Zenbook Fold */
    }
}

/* Adjustments for Nest Hub */
@media (min-width: 1024px) and (max-width: 1280px) {
    .login-container {
        margin-top: 150px; /* Further adjusted top margin for Nest Hub */
        margin-left: 250px; /* Adjusted margin to move right */
    }
}

/* Adjustments for Nest Hub Max */
@media (min-width: 1280px) and (max-width: 1280px) {
    .login-container {
        margin-top: 150px; /* Further adjusted top margin for Nest Hub Max */
        margin-left: auto;
        margin-right: 60px; /* Adjusted margin to move right */
    }
}

/* Adjustments for large screens with viewport width of 1300px or higher */
@media (min-width: 1300px) {
    .login-container {
        margin-top: 150px; /* Further adjusted top margin for large screens */
        margin-left: auto;
        margin-right: 60px; /* Adjusted margin to move right */
        max-width: 500px; /* Make the form wider for larger screens */
    }
}

/* Specific adjustments for iPhone SE */
@media (min-width: 320px) and (max-width: 375px) and (min-height: 568px) {
    .login-container {
        margin-top: 200px; /* Increased top margin for iPhone SE */
    }
}

/* Specific adjustments for iPhone XR */
@media (min-width: 414px) and (max-width: 896px) and (min-height: 896px) {
    .login-container {
        margin-top: 200px; /* Increased top margin for iPhone XR */
    }
}

/* Specific adjustments for iPhone 12 Pro */
@media (min-width: 390px) and (max-width: 844px) and (min-height: 844px) {
    .login-container {
        margin-top: 200px; /* Increased top margin for iPhone 12 Pro */
    }
}

/* Specific adjustments for iPhone 14 Pro Max */
@media (min-width: 430px) and (max-width: 932px) and (min-height: 932px) {
    .login-container {
        margin-top: 200px; /* Increased top margin for iPhone 14 Pro Max */
    }
}

/* Specific adjustments for Pixel 7 */
@media (min-width: 412px) and (max-width: 915px) and (min-height: 915px) {
    .login-container {
        margin-top: 200px; /* Increased top margin for Pixel 7 */
    }
}

/* Specific adjustments for Samsung Galaxy S8+ */
@media (min-width: 360px) and (max-width: 740px) and (min-height: 740px) {
    .login-container {
        margin-top: 200px; /* Increased top margin for Samsung Galaxy S8+ */
    }
}

/* Specific adjustments for Samsung Galaxy S20 Ultra */
@media (min-width: 412px) and (max-width: 915px) and (min-height: 915px) {
    .login-container {
        margin-top: 200px; /* Increased top margin for Samsung Galaxy S20 Ultra */
    }
}

/* Specific adjustments for iPad Mini */
@media (min-width: 768px) and (max-width: 834px) {
    .login-container {
        margin-top: 180px; /* Increased top margin for iPad Mini */
    }
}

/* Specific adjustments for Surface Duo */
@media (min-width: 540px) and (max-width: 720px) {
    .login-container {
        margin-top: 180px; /* Increased top margin for Surface Duo */
    }
}

/* Specific adjustments for Galaxy Z Fold 5 */
@media (min-width: 373px) and (max-width: 848px) and (min-height: 848px) {
    .login-container {
        margin-top: 200px; /* Increased top margin for Galaxy Z Fold 5 */
    }
}

/* Specific adjustments for Samsung Galaxy A51/71 */
@media (min-width: 412px) and (max-width: 915px) and (min-height: 915px) {
    .login-container {
        margin-top: 200px; /* Increased top margin for Samsung Galaxy A51/71 */
    }
}

/* Login Sign Up Disclaimer */
.disclaimer {
    margin-top: 20px;
    font-size: 0.85rem;
    color: #555;
    text-align: center;
}

.disclaimer a {
    color: #3897f0; /* Matches the link color of other hyperlinks */
    text-decoration: none; /* Ensures no underline by default */
}

.disclaimer a:hover {
    text-decoration: none; /* Keeps no underline on hover */
}
  
/* Adjustments for screens with viewport width 768px or smaller */
@media (max-width: 768px) {
    .login-container {
        margin: 100px auto; /* Reduced top margin to move the form slightly up */
        padding: 20px;
    }

    .login-header {
        margin-top: 30px; /* Increase spacing further for smaller screens if needed */
        font-size: 24px;
    }

    .login-input,
    .login-button {
        font-size: 16px;
    }

    .math-captcha {
        font-size: 16px; /* Adjust the font size for smaller screens */
    }
}

/* Adjustments for screens with viewport width 480px or smaller */
@media (max-width: 480px) {
    .login-container {
        margin: 120px auto; /* Adjusted top margin for mobile screens */
        padding: 15px;
    }

    .login-header {
        margin-top: 30px; /* Increase spacing further for smaller screens if needed */
        font-size: 22px; /* Adjust the font size for smaller screens */
    }

    .login-input,
    .login-button {
        font-size: 16px; /* Adjust font size if necessary for smaller screens */
    }

    .math-captcha {
        font-size: 14px; /* Adjust the font size for smaller screens */
    }
}