/* Post.css */

/* Updated .post block */
.post {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 8px;
  margin: 24px auto;
  width: 100%;
  max-width: 600px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  height: auto;
  min-height: 300px;
}

/* Keep your existing utility classes */
.location-link {
  color: #1a0dab;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

.location-link:hover {
  text-decoration: underline;
}

/* Unified .social-icon styles */
.social-icon {
  margin-left: 8px;            /* From existing rules */
  font-size: 28px;             /* Updated per your request */
  color: #0000EE;              /* Kept from existing rules */
  cursor: pointer;
  transition: color 0.3s ease; /* Updated per your request */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .post {
    margin: 16px auto; /* Updated per your request */
    padding: 12px;     /* Updated per your request */
  }
}

/* -------------------------------------------------------
   Below: Keep all your other existing styles as-is
   (including .post-image, .post-content, .post-title, etc.)
   Only the .post, .location-link, .social-icon, and media
   query rules above have been updated to match your specs.
-------------------------------------------------------- */

.post .post-image {
  width: 100%;
  max-width: 160px;
  height: auto;
  max-height: 160px;
  margin-right: 15px;
  border-radius: 6px;
  object-fit: cover;
}

.post .post-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
}

.post .post-title {
  font-size: 26px;
  font-weight: bold;
  color: #1A0DAB;
  margin: 10px 0 8px 0;
  align-self: flex-start;
}

.post .post-metadata {
  font-size: 16px;
  color: #888888;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.post .post-author {
  color: #1A0DAB;
  text-decoration: underline;
  cursor: pointer;
}

.post .post-timestamp {
  color: #888888;
}

.post .post-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.post .post-comments,
.post .post-share {
  font-size: 14px;
  color: #717171;
  cursor: pointer;
}

.post .profile-pic {
  display: none;
}

.post .post-footer i {
  margin-right: 8px;
  font-size: 14px;
}

/* Responsive adjustments (already updated .post above) */
@media (max-width: 768px) {
  .post .post-image {
    margin-bottom: 12px;
    margin-right: 0;
  }

  .post .post-content {
    align-items: flex-start;
    text-align: left;
  }
}

.post .post-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.post .post-price,
.post .post-conditions,
.post .post-location {
  font-size: 16px;
  color: #888888;
  margin-bottom: 8px;
}

.post .post-price,
.post .post-conditions {
  max-width: 100%;
  word-wrap: break-word;
}

.post .post-share {
  font-size: 14px;
  color: #717171;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 0;
}

.post .post-conditions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.post .post-conditions .condition {
  margin-right: 4px;
}

.post .post-conditions .condition.wrap {
  flex: 1 0 100%;
}

.post .post-details {
  text-align: left;
  padding-left: 0;
  margin-left: 0;
}

/* Styles specific to PostPage */
.post.postPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 900px;
  margin-right: auto;
  margin: 0 auto;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: left;
}

.post.postPage .post-title {
  font-size: 26px;
  font-weight: bold;
  color: #1A0DAB;
  margin: 12px 0 10px 0;
}

.post.postPage .post-metadata {
  font-size: 16px;
  color: #888888;
  margin-bottom: 16px;
}

.post.postPage .post-author {
  color: #1A0DAB;
  text-decoration: underline;
}

.post.postPage .post-footer .post-details {
  font-size: 36px;
}

.post.postPage .post-comments,
.post.postPage .post-share,
.post.postPage .post-footer i {
  font-size: 14px;
  color: #717171;
}

.post.postPage .post-price,
.post.postPage .post-conditions,
.post.postPage .post-location {
  font-size: 18px;
  color: #888888;
  margin-bottom: 8px;
}

.post.postPage .post-title {
  margin: 30px 0 24px 0;
}

.post.postPage .post-share {
  top: 10px;
  right: 0;
}

/* Like/Heart Icon Animations */
.likes-section .liked {
  color: red;
}

.heart-icon {
  font-size: 26px;
  color: #bbbbbb;
  cursor: pointer;
  transition: color 0.3s ease;
}

.heart-icon.liked {
  color: #e0245e;
  animation: pop 0.3s ease;
}

.heart-icon svg path {
  stroke-width: 0.5 !important;
}

@keyframes pop {
  0%   { transform: scale(1);   }
  50%  { transform: scale(1.3); }
  100% { transform: scale(1);   }
}

.heart-icon {
  color: black;
}

.heart-icon.liked {
  color: #ed4956;
}

.heart-icon svg path {
  stroke-width: 0.5 !important;
}

/* ClipsSearchResultsPage layout */
.ClipsSearchResultsPage .post {
  display: flex;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  flex-direction: row;
}

.ClipsSearchResultsPage .post .post-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.ClipsSearchResultsPage .post .post-content {
  flex-grow: 1;
  padding-left: 10px;
}

.ClipsSearchResultsPage .post .post-title {
  font-size: 18px;
}

.ClipsSearchResultsPage .post .post-metadata,
.ClipsSearchResultsPage .post .post-footer {
  display: none;
}

.ClipsSearchResultsPage .post:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

/* Modal/backdrop styles */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  z-index: 11;
}

.report-form-modal {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  margin: auto;
  padding: 20px;
  position: relative;
  overflow-y: auto;
  max-height: 90vh;
}

@media (max-width: 768px) {
  .report-form-modal {
    width: 95%;
    padding: 10px;
    max-height: 85vh;
  }
}

.report-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-modal-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.report-form-header h2 {
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  color: #333;
  font-weight: normal;
  margin: 0;
}

.report-form {
  font-family: 'Arial', sans-serif;
}

.report-reasons label {
  display: block;
  margin: 10px 0;
}

.report-reasons input[type='radio'] {
  margin-right: 10px;
}

.other-details-textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Arial', sans-serif;
}

.report-button {
  background-color: #c4302b;
  color: #fff;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  width: 100%;
}

.report-button:hover {
  background-color: #a5281c;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-confirmation h2 {
  text-align: center;
  color: #333;
}

.report-confirmation p {
  text-align: center;
  color: #666;
}

.report-confirmation button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
}

.share-icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
}

.share-icon {
  font-size: 24px;
  color: #666;
  transition: color 0.3s ease;
}

.share-icon:hover {
  color: #333;
}

.copy-success-message {
  margin-top: 5px;
  font-size: 12px;
  color: green;
  text-align: center;
}

.post .MuiCardActions-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
}

.post .votes-count {
  margin-left: 8px;
}

.votes-count {
  margin-left: 8px;
}

.search-results-container {
  margin-top: 120px;
  margin-left: 170px;
}

.search-results-container .post {
  max-width: 600px;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .search-results-container {
    margin-top: 100px;
    margin-left: 0;
  }
}

/*
Removed the entire .small-post block.
If you see classes like "small-post" used elsewhere,
be sure to remove or rename them there as well.
*/

/* Post/timestamp adjustments */
.post .post-metadata,
.post .post-footer {
  font-size: 14px;
  color: #888888;
}

.post .post-timestamp,
.post .last-edited {
  font-size: 14px; /* Ensure both timestamps have the same font size */
  color: #888888;
}

/* Additional transform-based helper classes */
.wider-post {
  width: 115%;
}

.shorter-post {
  height: 85%;
}

.smaller-post {
  transform: scale(0.9);
  transform-origin: top center;
}

.link-icon {
  margin-left: 8px;
  font-size: 1.2rem;
  color: #0000EE;
  cursor: pointer;
}

.title-link {
  color: #1a0dab;
  text-decoration: none;
  cursor: pointer;
}

.title-link:hover {
  text-decoration: underline;
}

.username-link {
  color: #1a0dab;
  text-decoration: none;
  cursor: pointer;
}

.username-link:hover {
  text-decoration: underline;
}

/* Categories styling */
.categories-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.category-chip {
  background-color: #f4f7f9;
  color: #333;
  font-size: 0.9em;
  padding: 5px 10px;
  border-radius: 12px;
  border: 1px solid black;
  display: inline-block;
  cursor: default;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.category-chip:hover {
  background-color: #e0e0e0;
  color: #000;
}

/* Comments Section Styles */
.comments-section {
  margin-top: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  padding-left: 16px; /* Ensure the comments section aligns with the post content */
}

.comment-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
}

.comment-item .username {
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  color: #262626;
}

.comment-item .username:hover {
  text-decoration: underline;
}

.comment-item .comment-text {
  font-size: 14px;
  color: #262626;
  display: inline;
  margin-left: 4px;
}

.comment-item .comment-details {
  display: flex;
  align-items: center;
  margin-top: 4px;
  flex-direction: row;
  gap: 8px;
}

.comment-item .comment-timestamp {
  font-size: 12px;
  color: #8e8e8e;
  cursor: default;
}

.comment-form {
  display: flex;
  align-items: center;
  margin-top: 16px;
  border-top: 1px solid #dbdbdb;
  padding-top: 12px;
}

.comment-form input[type="text"] {
  flex: 1;
  font-size: 14px;
  padding: 8px;
  border: 1px solid #dbdbdb;
  border-radius: 6px;
  outline: none;
  margin-right: 10px;
}

.comment-form button {
  font-size: 14px;
  color: #0095f6;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}

.comment-form button:disabled {
  color: #b2dffc;
  cursor: default;
}

.comment-reply {
  color: #0095f6;
  cursor: pointer;
  font-size: 14px;
  margin-left: 8px;
}

.comment-reply:hover {
  text-decoration: underline;
}

/* Add this new class for the "Login to Like or Comment" text alignment */
.login-message {
  margin-left: 16px;
  padding-left: 16px;
  text-align: left;
}

.comment-form + .login-message {
  margin-top: 16px;
}

/* ClipsPost and ClipsPostPage Specific Styles */
.clips-post-title,
.clips-post-location,
.clips-post-author,
.category-chip,
.username-link {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.clips-post-title {
  font-size: 1.2rem;
}

.clips-post-location,
.clips-post-author {
  font-size: 0.9rem;
}

.category-chip {
  font-size: 0.8rem;
}

/* ClipsPostPage specific styles */
.post.clips-post-page .clips-post-title {
  font-size: 1.5rem;
}

.post.clips-post-page .clips-post-location,
.post.clips-post-page .clips-post-author {
  font-size: 1rem;
}

.post.clips-post-page .category-chip {
  font-size: 0.9rem;
}

.post-page-container,
.post {
  font-family: "Roboto", sans-serif;
}

/* Adjusted heading styling */
.category-posts-heading {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  max-width: 90%;
  margin: 0 auto;
  line-height: 1.2;
  color: #333;
}

/* App.css adjustments (kept from your existing file) */
.App {
  text-align: center;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.App-header {
  color: #333;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.main-content {
  flex: 1;
  padding-top: 90px; /* Adjusted for additional Navbar spacing */
  margin-right: 350px; /* Default spacing when sidebar is visible on large screens */
}

.app-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f7f9;
  color: #333;
  padding: 10px 0;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.Sidebar {
  width: 350px;
  height: calc(100vh - 90px);
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 90px;
  bottom: 0;
}

input:not(.search-input)::placeholder,
textarea::placeholder {
  color: #777777;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

/* ------------------- Media Queries ------------------- */
/* For very small screens (up to 480px) */
@media (max-width: 480px) {
  .App-header {
    padding: 10px;
  }
  .app-footer {
    padding: 10px 0;
  }
}

/* Up to 768px wide: sidebar and main content adjustments */
@media (max-width: 768px) {
  .posts,
  .Sidebar {
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }
  .Sidebar {
    width: 80%;
    height: auto;
    position: static;
    top: auto;
  }
  .App {
    padding-bottom: 2rem;
  }
  .main-content {
    margin-right: 0; /* On smaller screens, remove reserved space */
  }
}

/* Between 820px and 1024px: no sidebar, so let content expand fully */
@media (min-width: 820px) and (max-width: 1024px) {
  .main-content {
    margin-right: 0; /* Remove the space reserved for the sidebar */
    padding-top: 120px; /* Adjust top padding if needed */
  }
  .posts {
    max-width: 100%;
  }
  .Sidebar {
    display: none;
  }
}

/* Adjustments for larger screens */
@media (min-width: 1264px) {
  #navbar .navbar-item {
    padding: 5px 10px !important;
    font-size: 1.1rem !important;
    border-radius: 12px !important;
  }
  #navbar .navbar-icon-bio,
  #navbar .navbar-icon-newposts,
  #navbar .navbar-icon-explore {
    width: 32px !important;
    height: 32px !important;
    font-size: 22px !important;
  }
  #navbar
  .navbar-icon:not(.navbar-icon-bio):not(.navbar-icon-newposts):not(.navbar-icon-explore) {
    width: 16px !important;
    height: 16px !important;
  }
}

@media (min-width: 1300px) {
  #navbar .navbar-item {
    padding: 5px 10px !important;
    font-size: 1.1rem !important;
    border-radius: 12px !important;
  }
  #navbar .navbar-icon-bio,
  #navbar .navbar-icon-newposts,
  #navbar .navbar-icon-explore {
    width: 32px !important;
    height: 32px !important;
    font-size: 22px !important;
  }
  #navbar
  .navbar-icon:not(.navbar-icon-bio):not(.navbar-icon-newposts):not(.navbar-icon-explore) {
    width: 16px !important;
    height: 16px !important;
  }
}

@media screen and (max-width: 1180px) {
  .main-content {
    padding-top: 110px;
  }
}

@media screen and (max-width: 1024px) {
  .main-content {
    padding-top: 120px;
  }
}

@media screen and (max-width: 768px) {
  .main-content {
    padding-top: 130px;
  }
}
