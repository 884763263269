/* UsersPosts.css */

.users-posts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 60px 20px;
    padding: 20px;
}

/* Adjustments for the heading */
.users-posts-container h1 {
    margin-bottom: 30px;
    text-align: center;
    width: 90%; /* Match the category-posts-heading max-width */
    margin: 0 auto 30px; /* Center the heading horizontally and add bottom margin */
    line-height: 1.2;
    color: #333;
    transform: none; /* Remove the transform property */
    padding-left: 0;
}

/* Responsive adjustments for medium to large screens */
@media screen and (min-width: 768px) {
    .users-posts-container {
        margin-top: 100px;
        margin-left: 160px;
        margin-right: auto;
        max-width: 768px;
        padding-left: 60px;
    }

    .users-posts-container h1 {
        transform: none; /* Remove the transform */
    }
}

@media screen and (min-width: 992px) {
    .users-posts-container {
        max-width: 900px;
        margin-top: 120px;
        margin-right: auto;
        margin-left: 160px;
        padding-left: 60px;
    }

    .users-posts-container h1 {
        transform: none; /* Remove the transform */
    }
}

/* Adjustments for screens smaller than 768px */
@media screen and (max-width: 768px) {
    .users-posts-container {
        margin-top: 100px !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
        padding-left: 10px !important;
    }

    .users-posts-container .clips-post {
        margin-left: 0 !important;
    }
}

/* Adjustments for very small screens */
@media screen and (max-width: 480px) {
    .users-posts-container {
        margin-top: 140px;
        margin-bottom: 20px;
        margin-left: 30px;
        margin-right: 5px;
        padding: 10px;
    }

    .users-posts-container h1 {
        margin-bottom: 40px;
    }
}
