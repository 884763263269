/* ViewContactList.css */

.view-contact-list-container {
  padding: 20px;
  margin-top: 80px; /* Default margin-top */
  padding-top: 40px;
  box-sizing: border-box;
}

/* Ensure box-sizing is consistent */
.view-contact-list-container *,
.view-contact-list-container *::before,
.view-contact-list-container *::after {
  box-sizing: border-box;
}

.header-box {
  border: 2px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 30px;
  background-color: #fff;
}

.header-box h1 {
  margin-top: 0;
  margin-bottom: 15px;
}

.header-box h3 {
  margin: 0;
  color: #666;
  font-weight: normal;
  text-align: center;
}

@media (min-width: 1200px) {
  .view-contact-list-container {
    margin-left: 300px;
    margin-right: 0;
    max-width: calc(100% - 300px);
  }
}

.view-contact-list-container .post-contact-list {
  margin-bottom: 30px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
}

.table-responsive {
  overflow-x: auto;
}

.view-contact-list-container table {
  width: 100%;
  border-collapse: collapse;
}

.view-contact-list-container th,
.view-contact-list-container td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.view-contact-list-container th {
  background-color: #f2f2f2;
  white-space: nowrap !important; /* Prevent header text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
}

.view-contact-list-container td {
  white-space: normal;  /* Allow text to wrap */
  word-break: break-word; /* Break long words */
}

.view-contact-list-container tr:nth-child(even) {
  background-color: #f9f9f9;
}

.view-contact-list-container h1 {
  text-align: center;
  margin-bottom: 30px;
}

.view-contact-list-container h2 {
  margin-top: 0;
  margin-bottom: 15px;
}

.view-contact-list-container p {
  text-align: center;
  font-style: normal;
  color: #666;
}

@media (max-width: 768px) {
  .view-contact-list-container {
    padding: 10px;
    margin-top: 130px; /* Keep increased margin-top */
  }

  .view-contact-list-container table {
    font-size: 14px;
  }

  .view-contact-list-container td {
    padding: 6px;
    white-space: normal;
    word-break: break-word;
  }

  .view-contact-list-container th {
    padding: 6px;
    white-space: nowrap !important;
  }

  .view-contact-list-container .post-contact-list {
    padding: 15px;
  }

  .header-box {
    padding: 15px;
  }
  
  .header-box h3 {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .view-contact-list-container {
    padding: 10px;
    margin-top: 130px; /* Keep increased margin-top */
  }

  .view-contact-list-container table {
    font-size: 12px;
  }

  .view-contact-list-container th,
  .view-contact-list-container td {
    padding: 4px;
  }

  .view-contact-list-container h1 {
    font-size: 24px;
  }

  .view-contact-list-container h2 {
    font-size: 20px;
  }

  .header-box {
    padding: 10px;
  }
  
  .header-box h3 {
    font-size: 14px;
  }
}

/* Adjustments for Galaxy Z Fold 5 and similar narrow screens */
@media (max-width: 653px) {
  .view-contact-list-container {
    padding: 10px;
    margin-top: 130px;
  }

  .view-contact-list-container table {
    font-size: 12px;
  }

  .view-contact-list-container td {
    padding: 4px;
    white-space: normal;
    word-break: break-word;
  }

  .view-contact-list-container th {
    padding: 4px;
    white-space: nowrap !important;
  }

  /* Reduce font sizes */
  .view-contact-list-container h1 {
    font-size: 20px;
  }

  .view-contact-list-container h2 {
    font-size: 18px;
  }

  .header-box h3 {
    font-size: 14px;
  }
}
