/* ContactForm.css */

.contact-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 0 20px;
    margin-top: 80px;
    margin-right: 0;
    padding-left: 60px;
}

.contact-form {
    background-color: #fff;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    width: 100%;
    margin: 80px auto;
}

.contact-form-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    padding: 20px;
    font-weight: 600;
    font-size: 24px;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 40px;
    margin: 10px auto 20px;
    width: 80%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.contact-form-field {
    width: 100%;
    margin-top: 20px;
}

.contact-form-field input,
.contact-form-field textarea {
    width: 100%;
    padding: 20px;
    font-size: 1rem;
    border-radius: 15px;
    border: 1px solid #e0e0e0;
    outline: none;
}

.contact-form-field textarea {
    min-height: 150px;
    resize: vertical;
}

.contact-form-button {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.contact-form-button {
    padding: 10px 20px;
    background-color: #0095f6;
    color: #ffffff;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    text-transform: none;
}

.contact-form-button:hover {
    background-color: #0077cc;
}

/* Tablet and smaller screens */
@media (max-width: 768px) {
    .contact-form-container {
        padding: 0;
        margin-top: 120px;
        padding-left: 40px;
    }
    .contact-form {
        padding: 20px;
        border-radius: 20px;
        width: 90% !important;
    }
    .contact-form-header {
        padding: 15px;
        font-size: 20px;
        border-radius: 40px;
        margin: 10px auto 20px;
        width: 90%;
    }
    .contact-form-field input,
    .contact-form-field textarea {
        padding: 15px;
        font-size: 0.9rem;
        border-radius: 15px;
    }
    .contact-form-button button {
        padding: 8px 16px;
        font-size: 0.9rem;
        border-radius: 15px;
    }
}

/* Mobile screens (480px or smaller) */
@media (max-width: 480px) {
    .contact-form-container {
        margin-top: 60px; 
        padding-left: 5px; 
    }
    .contact-form {
        width: 100% !important;
        margin: 20px auto;
        border-radius: 20px;
    }
    .contact-form-header {
        font-size: 18px;
        border-radius: 40px;
        margin: 10px auto 20px;
        width: 90%;
    }
    .contact-form-field input,
    .contact-form-field textarea {
        font-size: 0.8rem;
        border-radius: 15px;
    }
    .contact-form-button button {
        font-size: 0.8rem;
        border-radius: 15px;
    }
}

/* Larger screens */
@media (min-width: 768px) {
    .contact-form-container {
        padding-left: 250px;
    }
}

@media (min-width: 1300px) {
    .contact-form-container {
        margin-top: 60px;
        padding-left: 270px;
    }
}

/* Additional device-specific queries */
@media (max-width: 375px) and (max-height: 667px) {
    .contact-form-container {
        margin-top: 130px;
        padding-left: 35px;
    }
}

@media (max-width: 360px) and (max-height: 740px) {
    .contact-form-container {
        margin-top: 130px;
        padding-left: 35px;
    }
}

@media (min-width: 540px) and (min-height: 720px) and (max-width: 720px) {
    .contact-form-container {
        margin-top: 80px;
        padding-left: 50px;
    }
}

@media (min-width: 1024px) and (max-width: 1280px) and (min-height: 600px) {
    .contact-form-container {
        margin-top: 40px;
        padding-left: 60px;
    }
}

@media (min-width: 1280px) and (max-width: 1920px) and (min-height: 800px) {
    .contact-form-container {
        margin-top: 40px;
        padding-left: 80px;
    }
}

@media (min-width: 1264px) and (max-width: 1264px) {
  .contact-form-container {
    margin-left: 140px;
    padding-left: 80px;
  }
  .contact-form {
    margin-left: 140px;
  }
}

@media (min-width: 1181px) and (max-width: 1280px) {
    .contact-form-container {
        padding-left: 200px !important;
    }
    .contact-form {
        width: 650px !important;
        max-width: none !important; 
    }
}

/* ========================================================= */
/* New Device-Specific Adjustments Based on Requirements     */
/* ========================================================= */

/* iPhone SE (375px) and Samsung Galaxy S8+ (360px): Shift form slightly to the left */
@media screen and (max-width: 380px) {
    .contact-form-container {
        padding-left: 0px !important; /* Shift slightly to the left */
    }
}

/* iPad Air (~820px), iPad Pro (~834px), Surface Pro 7 (~912px), 
   Asus Zenbook Fold (1920px): Shift form up */

/* iPad Air (~820px wide) */
@media screen and (min-width: 810px) and (max-width: 830px) {
    .contact-form-container {
        margin-top: 40px !important; /* Shift up */
    }
}

/* iPad Pro (~834px wide) */
@media screen and (min-width: 830px) and (max-width: 850px) {
    .contact-form-container {
        margin-top: 40px !important; /* Shift up */
    }
}

/* Surface Pro 7 (~912px wide) */
@media screen and (min-width: 900px) and (max-width: 920px) {
    .contact-form-container {
        margin-top: 40px !important; /* Shift up */
    }
}

/* Asus Zenbook Fold (1920px wide) */
@media screen and (min-width: 1920px) {
    .contact-form-container {
        margin-top: 40px !important; /* Shift up */
    }
}

/* Galaxy Z Fold 5 (~1812px wide): Shift form slightly up */
@media screen and (min-width: 1800px) and (max-width: 1820px) {
    .contact-form-container {
        margin-top: 50px !important; /* Slightly up */
    }
}



