/* Footer.css */

.app-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f7f9;
  color: #333;
  padding: 15px 0;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  font-family: 'Roboto', sans-serif;
}

.footer-icons {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.footer-icon {
  color: #333;
  font-size: 28px;
  padding: 0 15px;
}

.footer-icon--new {
  font-size: 32px;
}

.footer-icon:hover {
  color: #007bff;
}

.footer-icon svg {
  font-size: 28px;
}

.footer-icon:not(:last-child):hover::after {
  content: '';
  display: block;
  margin: auto;
  height: 2px;
  width: 30px;
  background: #007bff;
  transition: width 0.3s;
}

/* Hide the footer when the sidebar is open on tablet/mobile screens */
@media (max-width: 1180px) {
  body.sidebar-open .app-footer {
      display: none;
  }
}

/* Hide footer on desktop screens */
@media (min-width: 1181px) {
  .app-footer {
      display: none;
  }
}

/* Show footer on tablet/mobile screens */
@media (max-width: 1180px) {
  .app-footer {
      display: flex;
  }
}

/* Device-specific adjustments */
/* iPad Air, iPad Pro */
@media only screen 
  and (min-device-width: 820px) 
  and (max-device-width: 1180px) {
  .footer-icon {
      font-size: 32px;
      padding: 0 20px;
  }
  
  .footer-icon--new {
      font-size: 36px;
  }
  
  .footer-icon svg {
      font-size: 32px;
  }
}

/* Surface Pro 7, Nest Hub */
@media only screen 
  and (min-device-width: 912px) 
  and (max-device-width: 1180px) {
  .footer-icon {
      font-size: 32px;
      padding: 0 25px;
  }
  
  .footer-icon--new {
      font-size: 36px;
  }
  
  .footer-icon svg {
      font-size: 32px;
  }
}

/* Smaller screens */
@media (max-width: 480px) {
  .footer-icon {
      font-size: 24px;
      padding: 0 10px;
  }
  
  .footer-icon--new {
      font-size: 28px;
  }
  
  .footer-icon svg {
      font-size: 24px;
  }
}
